import { useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  Text,
} from "@shopify/polaris";
import { PlusIcon } from "@shopify/polaris-icons";
import FieldSettingItem from "./FieldSettingItem";
import { useRecoilState } from "recoil";
import { appearanceFieldsState } from "../../../../store/appearanceSettingAtom";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

const FieldsSetting = ({ isOpen, toggle }) => {
  const shopify = useAppBridge();

  let [fields, setFields] = useRecoilState(appearanceFieldsState);

  let [openAccord, setOpenAccord] = useState(-1);
  let toggleAccord = (index) => {
    if (openAccord == index) {
      setOpenAccord(-1);
    } else {
      setOpenAccord(index);
    }
  };

  let handleFieldRemove = (fieldIndex) => {
    setFields(fields.toSpliced(fieldIndex, 1));
  };

  let handleFieldChange = (fieldIndex, key, value) => {
    let xFields = JSON.parse(JSON.stringify(fields));
    xFields[fieldIndex][key] = value;
    setFields(xFields);
  };

  let [selectedFields, setSelectedFields] = useState([]);
  let toggleAddFieldModal = () => {
    shopify.modal.toggle("my-modal");
  };
  let handleAddFieldSubmit = () => {
    let newField = {};
    selectedFields.map((name) => {
      switch (name) {
        case "hidden":
          newField = {
            type: "hidden",
            label: "Hidden",
            defaultValue: "",
          };
          break;
        case "accept-terms":
          newField = {
            type: "accept-terms",
            label:
              "I agree <a href='#' target='_blank'>Terms and Conditions</a>",
            defaultSelected: false,
            defaultValue: "",
            required: false,
          };
          break;
        case "text":
          newField = {
            type: "text",
            label: "Text",
            placeholder: "Enter text",
            showLabel: false,
            required: false,
            showRequired: false,
          };
          break;
        case "textarea":
          newField = {
            type: "textarea",
            label: "Textarea",
            placeholder: "Enter text",
            showLabel: false,
            required: false,
            showRequired: false,
          };
          break;
        case "select":
          newField = {
            type: "select",
            label: "Select",
            placeholder: "Please select",
            showLabel: false,
            required: false,
            showRequired: false,
            options: ["option 1", "option 2"],
            defaultSelected: "",
          };
          break;
        case "radio":
          newField = {
            type: "radio",
            label: "Radio",
            showLabel: false,
            required: false,
            showRequired: false,
            options: ["option 1", "option 2"],
            defaultSelected: "",
            optionsPerLine: "1",
          };
          break;
        case "checkbox":
          newField = {
            type: "checkbox",
            label: "Checkbox",
            showLabel: false,
            required: false,
            showRequired: false,
            options: ["option 1", "option 2"],
            defaultSelected: "",
            optionsPerLine: "1",
          };
          break;
        case "phone":
          newField = {
            type: "phone",
            label: "Phone number",
            placeholder: "Enter phone number",
            showLabel: false,
            required: false,
            showRequired: false,
            defaultSelected: "pk",
          };
          break;
        default:
          console.log("you added default");
          break;
      }
    });

    setFields(fields.concat(newField));
    setSelectedFields([]);
    toggleAddFieldModal();
  };

  // drag code
  const [draggingIndex, setDraggingIndex] = useState(null);

  // Handle the start of dragging
  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  // Handle the drop event
  const handleDrop = (index) => {
    if (draggingIndex !== null) {
      let updatedFields = JSON.parse(JSON.stringify(fields));
      const draggedItem = updatedFields.splice(draggingIndex, 1)[0]; // Remove the dragged item
      updatedFields.splice(index, 0, draggedItem); // Insert the dragged item at the drop position
      setFields(updatedFields); // Update state
      setDraggingIndex(null); // Reset dragging index
    }
  };

  return (
    <Card padding={"0"} roundedAbove="xs">
      <Button
        variant="tertiary"
        fullWidth
        size="large"
        textAlign="left"
        disclosure={isOpen ? "up" : "down"}
        onClick={toggle}
      >
        <Box paddingBlock={"150"} paddingInline={"100"}>
          <h1 className="back-in-stock-right-card-title-style">Fields setting</h1>
        </Box>
      </Button>
      <Collapsible
        open={isOpen}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Box paddingInline={"400"} paddingBlockEnd={"400"}>
          <BlockStack gap={"400"} inlineAlign="start">
            <Text as="p" variant="bodyMd">
              Change the fields content here
            </Text>
            {fields.length > 0 && (
              <Box
                width="100%"
                borderWidth="025"
                borderColor="border"
                borderRadius="200"
              >
                {fields.map((field, index, arr) => (
                  <FieldSettingItem
                    key={index}
                    index={index}
                    field={field}
                    onFieldChange={handleFieldChange}
                    onFieldRemove={handleFieldRemove}
                    isLastItem={index + 1 == fields.length}
                    isOpen={openAccord == index}
                    onToggle={() => toggleAccord(index)}
                    onDragCustomStart={handleDragStart}
                    onCustomDrop={handleDrop}
                  />
                ))}
              </Box>
            )}
            <Button icon={PlusIcon} onClick={toggleAddFieldModal}>
              Add fields
            </Button>
          </BlockStack>
        </Box>
      </Collapsible>
      {/* add field modal */}
      <Modal id="my-modal">
        <TitleBar title="Include a field in the back-in-stock form.">
          <button
            variant="primary"
            onClick={handleAddFieldSubmit}
            disabled={selectedFields.length == 0}
          >
            Add
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <ChoiceList
            title={
              <Text as="p" variant="bodyLg" fontWeight="medium">
                Select fields to add
              </Text>
            }
            choices={[
              {
                label: "Phone",
                value: "phone",
                disabled:
                  fields.filter((field) => field.type == "phone").length > 0,
              },
              { label: "Hidden", value: "hidden" },
              { label: "Accept terms", value: "accept-terms" },
              { label: "Text", value: "text" },
              { label: "Textarea", value: "textarea" },
              { label: "Select", value: "select" },
              { label: "Radio", value: "radio" },
              { label: "Checkbox", value: "checkbox" },
            ]}
            selected={selectedFields}
            onChange={setSelectedFields}
          />
        </Box>
      </Modal>
    </Card>
  );
};

export default FieldsSetting;
