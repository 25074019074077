import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  ChoiceList,
  InlineStack,
  Page,
  Text,
  BlockStack,
  Grid,
} from "@shopify/polaris";
import { GetApiCall, ApiCall } from "../helpers/axios";

import { useSelector } from "react-redux";
const BackInStock_setting_location = () => {
  let navigate = useNavigate();

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(()=>{
    getBackInStockSettings();
    getLocations();

  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      let locations = result?.data?.data?.locations ? JSON.parse(result?.data?.data?.locations) : [];
      let selected_location = locations.map((item) => {
        console.log("item", item);
        return item
      });
      console.log(selected_location)
      setSelectedLocations(selected_location);
      setSettingID(result?.data?.data?.id);
    }
  };

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };
  const getLocations = async () => {
    let data = {
      store_client_id: store_client_id
    };
    console.log("data", data);
    let res = await ApiCall("POST", "/get_locations", data, header);
    if (res.status === 200) {
      //choices
      let choices = res.data.data.map((item) => {
        
        return { label: item.node.name, value: item.node.id };
      });
      console.log("choices", choices);
      setLocations(choices);

    }
  };
  let handleSubmit = async() => {
    console.log(selectedLocations);
    setLoading(true);
    let data_back_in_stock = {
      locations:selectedLocations,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
  };

  return (
    <Page title="Locations" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid gap={"400"}>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Shopify locations
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <h1 className="back-in-stock-right-card-title-style">Choose locations</h1>
                <Text as="p" variant="bodyLg">
                  Choose the locations to connect to Back in Stock that are able
                  to fulfill online orders. Notifications will only be sent if
                  there is an available variant in the connected locations. The
                  connected locations do not impact the visibility of the button
                  in the storefront.
                </Text>
                <ChoiceList
                  allowMultiple
                  choices={locations}
                  selected={selectedLocations}
                  onChange={(value) => {
                    console.log("Newly selected locations:", value);
                    setSelectedLocations(value);
                  }}
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_location;
