import { useState, useCallback, useEffect } from "react";
import {
  IndexTable,
  Card,
  IndexFilters,
  useSetIndexFiltersMode,
  IndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  InlineStack,
  Thumbnail,
  Button,
  Box,
  Spinner,
} from "@shopify/polaris";
import { DeleteIcon, EmailFollowUpIcon } from "@shopify/polaris-icons";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { ApiCall, GetApiCall } from "../../../helpers/axios";
import { useSelector } from "react-redux";
import { format } from "date-fns";
function MainTable() {
  const shopify = useAppBridge();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  const [statusFilter, setStatusFilter] = useState(undefined);
  const [queryValue, setQueryValue] = useState("");

  const handleStatusFilterChange = useCallback((value) => {
    setStatusFilter(value);
    handelSubscribers(value);
  }, []);
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    handelSubscribers("all", 1, value);
    console.log("queryValue", value);
  }, []);
  const handleStatusFilterRemove = useCallback(
    () => {
      setStatusFilter(undefined);
      handelSubscribers("all",1,null);
    },
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleStatusFilterRemove();
    handleQueryValueRemove();
  }, [handleStatusFilterRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          choices={[
            { label: "All submissions", value: "all" },
            { label: "Email sent", value: "email-sent" },
            { label: "Email pending", value: "email-pending" },
          ]}
          selected={statusFilter || []}
          onChange={handleStatusFilterChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (statusFilter && !isEmpty(statusFilter)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, statusFilter[0]),
      onRemove: handleStatusFilterRemove,
    });
  }

  const [tableData, setTableData] = useState([]);
  const resourceName = {
    singular: "result",
    plural: "results",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(tableData, {
    resourceIDResolver: (item) => item.variant_id,
  });

  const handleSelectionChangeFixed = (
    selectionType,
    toggleType,
    selection,
    position
  ) => {
    console.log(
      "selectedItems",
      selectionType,
      toggleType,
      selection,
      position
    );
    if (selectionType == "page") {
      let all_variant_id = tableData.map((item) => item.variant_id);
      handleSelectionChange(selectionType, toggleType , all_variant_id  , position);
    } else {
      console.log("selectionType 2", selectionType);
      handleSelectionChange(selectionType, toggleType, selection, position);
    }
  };

  const tableHeadings = [
    { title: "Product" },
    { title: "SKU" },
    { title: "Available quantity" },
    { title: "Subscriber(s)" },
    { title: "Last added" },
    { title: "Action" },
  ];

 
  useEffect(() => {
    handelSubscribers();
  }, []);

  const toggleDeleteProductModal = () => {
    shopify.modal.toggle("delete-product-modal");
  };

  let handleRemoveTableRecord = () => {
    toggleDeleteProductModal();
  };

  let handleRemoveTableRecordSubmit = async() => {
    console.log("items deleted", selectedResources);
    let res = await ApiCall("DELETE", "/delete-submission", { id:selectedResources  }, header);
    console.log("items deleted", selectedResources);
    if (res?.data?.statusCode === 200) {
      handelSubscribers(statusFilter);
    }
    clearSelection();
    toggleDeleteProductModal();
  };

  const toggleSendEmailModal = () => {
    shopify.modal.toggle("send-email-modal");
  };

  let sendEmail = () => {
    toggleSendEmailModal();
  };

  const handlePaginationNext = () => {
    if (currentPage < totalPages) {
      handelSubscribers(statusFilter, currentPage + 1);
    }
  };

  const handlePaginationPrevious = () => {
    if (currentPage > 1) {
      handelSubscribers(statusFilter, currentPage - 1);
    }
  };

  let handleSendEmailSubmit = async() => {
    console.log("email sent to: ", selectedResources);
    let data = {
      store_client_id: store_client_id,
      selectedResources: selectedResources,
    };
    try {  
      let res = await ApiCall("POST", "/send-mail-back-in-stock", data, header);  
      if (res.data.statusCode === 200) {
        console.log("Email sent successfully: ", res.data.message);
      } else {
        console.error("Error sending email: ", res.data.message);
      }
    } catch (error) {
      console.error("API call failed: ", error);
    }
    clearSelection();
    toggleSendEmailModal();
  };

  const handelSubscribers = async (filter, page = 1, value = null) => {
    try {
      setLoading(true);
      let queryValue = value ? value : null;
      filter = filter ? filter[0] : "all";
      let data = {
        store_client_id: store_client_id,
        export_type: filter,
        page: page,
        search: queryValue,
      };
      console.log("data", data);
      let res = await ApiCall("POST", "/get_subscribers", data, header);
      if (res.status === 200) {
        setTableData(res?.data?.data?.select_data || []);
        setTotalPages(res?.data?.data?.total_page || 1); // Update total pages
        setCurrentPage(page); // Update current page
        console.log("res", res);
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
      console.log("error", error);
    }
    setLoading(false);
  };

  return (
    <Card padding={"0"}>
      <IndexFilters
        // sortOptions={sortOptions}
        // sortSelected={sortSelected}
        // onSort={setSortSelected}
        queryValue={queryValue}
        queryPlaceholder="Search by product title or variant title, sku"
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue("")}
        tabs={[]}
        filters={filters}
        appliedFilters={appliedFilters}
        onClearAll={handleFiltersClearAll}
        mode={mode}
        setMode={setMode}
      />
      {loading ? (
        <div className="d-flex justify-content-center pt-3">
          <Spinner accessibilityLabel="Loading products" size="large" />
        </div>
      ) : (
        <IndexTable
          resourceName={resourceName}
          itemCount={tableData.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChangeFixed}
          headings={tableHeadings}
          pagination={{
            hasNext: currentPage < totalPages,
            hasPrevious: currentPage > 1,
            onNext: () => {
              handlePaginationNext();
            },
            onPrevious: () => {
              handlePaginationPrevious();
            },
          }}
          promotedBulkActions={[
            {
              content: "Send mail",
              onAction: sendEmail,
            },
            {
              content: "Delete",
              onAction: handleRemoveTableRecord,
            },
          ]}
        >
          {tableData.map((item, index) => (
            <IndexTable.Row
              id={item.variant_id}
              key={item.variant_id}
              selected={selectedResources.includes(item.variant_id)}
              position={index}
            >
              <IndexTable.Cell>
                <InlineStack blockAlign="center" gap={"2"}>
                  <Thumbnail size="small" source={`${item?.image}`} />
                  {item.product}
                </InlineStack>
              </IndexTable.Cell>
              <IndexTable.Cell>{item.sku}</IndexTable.Cell>
              <IndexTable.Cell>{item?.inventory_quantity}</IndexTable.Cell>
              <IndexTable.Cell>{item?.total_subscribers}</IndexTable.Cell>
              <IndexTable.Cell>{item.last_added}</IndexTable.Cell>
              <IndexTable.Cell>
                <InlineStack gap={"100"}>
                  <Button icon={DeleteIcon} onClick={handleRemoveTableRecord} />
                  <Button icon={EmailFollowUpIcon} onClick={sendEmail} />
                </InlineStack>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      )}
      {/* delete confirmation modal */}
      <Modal id="delete-product-modal">
        <TitleBar title="Delete Product">
          <button variant="primary" onClick={handleRemoveTableRecordSubmit}>
            Delete
          </button>
          <button onClick={toggleDeleteProductModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          Are you sure, you want to delete{" "}
          {selectedResources.length > 1 ? "these products?" : "this product?"}
        </Box>
      </Modal>

      {/* email confirmation modal */}
      <Modal id="send-email-modal">
        <TitleBar title="Are you sure you want to send email?">
          <button variant="primary" onClick={handleSendEmailSubmit}>
            Send
          </button>
          <button onClick={toggleSendEmailModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          You're about to send an email to customers registered for product(s),
          are you sure you want to send it?
        </Box>
      </Modal>
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        if (value == "all") return `Status: All submissions`;
        if (value == "email-sent") return `Status: Email sent`;
        if (value == "email-pending") return `Status: Email pending`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

export default MainTable;
