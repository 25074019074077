import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Grid,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import ColorPicker from "../../color-picker/ColorPicker";
import { useRecoilState } from "recoil";
import { appearanceEmailButtonState } from "../../../../store/appearanceSettingAtom";

const ButtonStyles = ({ isOpen, toggle }) => {
  let [form, setForm] = useRecoilState(appearanceEmailButtonState);

  // let fontFamilyOption = [
  //   { label: "Inter", value: "Inter" },
  //   { label: "Arial", value: "Arial" },
  //   { label: "Serif", value: "Serif" },
  //   { label: "Sans-Serif", value: "Sans-Serif" },
  // ];
  let fontWeightOption = [
    { label: "Normal", value: "400" },
    { label: "Medium", value: "500" },
    { label: "Semi bold", value: "600" },
    { label: "Bold", value: "700" },
    { label: "Extra bold", value: "800" },
  ];

  let handleFormChange = (key, value) => {
    let xForm = JSON.parse(JSON.stringify(form));
    xForm[key] = value;
    setForm(xForm);
  };

  let handleInputNumberChange = (key, value) => {
    if (!isNaN(value)) {
      handleFormChange(key, value);
    }
  };

  return (
    <Card roundedAbove="xs" padding={"0"}>
      <Button
        variant="tertiary"
        fullWidth
        size="large"
        textAlign="left"
        disclosure={isOpen ? "up" : "down"}
        onClick={toggle}
      >
        <Box paddingBlock={"150"} paddingInline={"100"}>
          <h1 className="back-in-stock-right-card-title-style">Button styles</h1>
        </Box>
      </Button>
      <Collapsible
        open={isOpen}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Box paddingInline={"400"}>
          <Text as="p" variant="bodyMd">
            Customize the "Email when available" button.
          </Text>
          <Box paddingBlockEnd={"400"}>
            <Grid gap={"400"} columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
              <TextField
                label="Font size"
                suffix="px"
                value={form.fontSize}
                onChange={(value) => handleInputNumberChange("fontSize", value)}
              />
              {/* <Select
                label="Font family"
                options={fontFamilyOption}
                value={form.fontFamily}
                onChange={(value) => handleFormChange("fontFamily", value)}
              /> */}
              <Select
                label="Font weight"
                options={fontWeightOption}
                value={form.fontWeight}
                onChange={(value) => handleFormChange("fontWeight", value)}
              />
              <TextField
                label="Padding"
                suffix="px"
                value={form.padding}
                onChange={(value) => handleInputNumberChange("padding", value)}
              />
              <TextField
                label="Border radius"
                suffix="px"
                value={form.borderRadius}
                onChange={(value) =>
                  handleInputNumberChange("borderRadius", value)
                }
              />
              <TextField
                label="Border width"
                suffix="px"
                value={form.borderWidth}
                onChange={(value) =>
                  handleInputNumberChange("borderWidth", value)
                }
              />
              <ColorPicker
                label="Text color"
                value={form.textColor}
                onChange={(value) => handleFormChange("textColor", value)}
              />
              <ColorPicker
                label="Background color"
                value={form.backgroundColor}
                onChange={(value) => handleFormChange("backgroundColor", value)}
              />
              {/* <Grid.Cell columnSpan={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}> */}
              <ColorPicker
                label="border color"
                value={form.borderColor}
                onChange={(value) => handleFormChange("borderColor", value)}
              />
              {/* </Grid.Cell> */}
            </Grid>
          </Box>
          <Bleed marginInline={"400"}>
            <Box
              padding={"400"}
              borderBlockStartWidth="025"
              borderColor="border"
            >
              <BlockStack gap={"300"}>
                <h1 className="back-in-stock-right-card-title-style">Button text</h1>
                <TextField
                  label="Change the button text here"
                  value={form.buttonText}
                  onChange={(value) => handleFormChange("buttonText", value)}
                />
              </BlockStack>
            </Box>
          </Bleed>
        </Box>
      </Collapsible>
    </Card>
  );
};

export default ButtonStyles;
