import { useState, useEffect } from "react";
import {
  AppProvider,
  Page,
  Card,
  InlineStack,
  BlockStack,
  Thumbnail,
  Text,
  Link as PolarisLink,
} from "@shopify/polaris";
import CustomHeader from "../components/CustomHeader";
import { ImageIcon, SettingsFilledIcon, FilterIcon, DeliveryFilledIcon, DnsSettingsIcon} from "@shopify/polaris-icons";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ApplicationStatus from "../components/ApplicationStatus";
import Footer from "./Footer";
import { TitleBar } from "@shopify/app-bridge-react";

const Setting = () => {
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo.auth_token };
  const shop_data = shopinfo.shopInfo;
  let block_status = shopinfo.block_status;
  let store_client_id = shop_data.store_client_id;
  const [blockStatus, setBlockStatus] = useState(false);

  useEffect(() => {
    if (block_status === false && Number(shop_data.app_status) === 0) {
      setBlockStatus(true);
    } else if (block_status === true && Number(shop_data.app_status) === 1) {
      setBlockStatus(true);
    }
  }, [store_client_id, shop_data]);

  let settings = [
    {
      icon: (<svg width={20} height={20} viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path fill-rule="evenodd" d="M8.013 4.389c0-.767.621-1.389 1.389-1.389h1.196c.767 0 1.39.622 1.39 1.389v.66c0 .153.101.33.307.436.141.074.278.155.411.241.196.128.402.13.536.052l.576-.332a1.389 1.389 0 0 1 1.897.508l.599 1.037a1.39 1.39 0 0 1-.509 1.897l-.621.359c-.131.075-.232.249-.225.477a5.135 5.135 0 0 1-.004.427c-.012.233.09.412.223.489l.627.362c.665.384.892 1.233.509 1.897l-.599 1.037a1.39 1.39 0 0 1-1.897.508l-.672-.388c-.132-.076-.332-.076-.526.045a4.928 4.928 0 0 1-.325.185c-.206.108-.308.284-.308.437v.778a1.39 1.39 0 0 1-1.389 1.39h-1.196a1.389 1.389 0 0 1-1.39-1.39v-.778c0-.153-.102-.33-.307-.437a4.96 4.96 0 0 1-.325-.185c-.194-.121-.395-.12-.526-.045l-.672.388a1.39 1.39 0 0 1-1.898-.508l-.598-1.037a1.389 1.389 0 0 1 .509-1.897l.627-.362c.133-.077.235-.256.223-.49a5.03 5.03 0 0 1-.004-.426c.007-.228-.094-.401-.225-.477l-.621-.359a1.389 1.389 0 0 1-.509-1.897l.598-1.037a1.389 1.389 0 0 1 1.898-.508l.576.332c.133.078.34.076.535-.052a4.81 4.81 0 0 1 .412-.24c.205-.108.308-.284.308-.437v-.66Zm1.987 7.611a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path></svg>),
      title: "General Setting",
      description:
        "Update your general details i.e Application status, Email settings, Billing settings and Customer portal",
      link: "/defaultSetting",
    },
    {
      icon: (<svg width={20} height={20} viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M3 6a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5h-12.5a.75.75 0 0 1-.75-.75Z"></path><path d="M6.75 14a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z"></path><path d="M5.5 9.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"></path></svg>),
      title: "Style Setting",
      description: "View and update personalize your design",
      link: "/style-setting",
    },
    {
      icon: (<svg width={20} height={20} viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M8 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path><path fill-rule="evenodd" d="M3.5 5.25c0-.966.783-1.75 1.75-1.75h9.5c.966 0 1.75.784 1.75 1.75v2.5a1.75 1.75 0 0 1-1.75 1.75h-9.5a1.75 1.75 0 0 1-1.75-1.75v-2.5Zm1.75-.25a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25h-9.5Z"></path><path d="M5.25 12a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h5a.75.75 0 0 1 0 1.5h-5a1.75 1.75 0 0 1-1.75-1.75v-2.5c0-.966.783-1.75 1.75-1.75h6.75a.75.75 0 0 1 0 1.5h-6.75Z"></path><path d="M7 14.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path><path fill-rule="evenodd" d="M16.878 13.839a2.257 2.257 0 0 1 0 .822l.327.19a.75.75 0 0 1-.75 1.298l-.328-.189a2.245 2.245 0 0 1-.712.412v.378a.75.75 0 0 1-1.5 0v-.378a2.248 2.248 0 0 1-.712-.412l-.328.19a.75.75 0 0 1-.75-1.3l.328-.188a2.26 2.26 0 0 1 0-.824l-.328-.189a.75.75 0 0 1 .75-1.299l.328.19a2.25 2.25 0 0 1 .712-.412v-.378a.75.75 0 0 1 1.5 0v.378c.263.093.504.234.712.412l.328-.19a.75.75 0 0 1 .75 1.3l-.327.189Zm-2.963.411c0-.133.035-.258.096-.367l.005-.008.004-.008a.75.75 0 0 1 1.289-.002l.006.01.006.01a.747.747 0 0 1 0 .73l-.006.01-.006.01a.75.75 0 0 1-1.29-.002l-.003-.008-.005-.008a.747.747 0 0 1-.096-.367Z"></path></svg>),
      title: "Advance Setting",
      description: "View and update personalize your design",
      link: "/advanced-setting",
    },
    {
      icon: (<svg width={20} height={20} viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path fill-rule="evenodd" d="M4.75 4.5a.75.75 0 0 0 0 1.5h3.25a1 1 0 0 1 0 2h-4.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 0 0 1.5h.458a2.5 2.5 0 1 0 4.78.75h3.024a2.5 2.5 0 1 0 4.955-.153 1.75 1.75 0 0 0 1.033-1.597v-1.22a1.75 1.75 0 0 0-1.326-1.697l-1.682-.42a.25.25 0 0 1-.18-.174l-.426-1.494a2.75 2.75 0 0 0-2.645-1.995h-6.991Zm2.75 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path></svg>),
      title: "Installation Page",
      description: "View and select theme to enable theme extension",
      link: "/install-uninstall",
    },
  ];

  return (
    <>
      {window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Setting"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" && (
        <AppProvider>
          <Page>
            <TitleBar title="Settings" />
            <ApplicationStatus header={header} shop_data={shopinfo} />
            <Card>
              <div className="settings-page">
                {settings.map((item, index) => (
                  <div className="setting-page-item" key={index}>
                    <Link
                      to={item.link}
                      className="d-flex gap-3 text-decoration-none"
                    >
                      <InlineStack gap={"400"} wrap={false} blockAlign="center">
                        <div className="icon">{item.icon}</div>
                        {/* <Thumbnail source={item.icon} size="small" /> */}
                        <BlockStack gap="050">
                          <Text as="h1" variant="headingMd" fontWeight="semibold">
                            <PolarisLink removeUnderline>
                              {item.title}
                            </PolarisLink>
                          </Text>
                          <Text as="h1" variant="bodySm" tone="subdued">
                            {item.description}
                          </Text>
                        </BlockStack>
                      </InlineStack>
                    </Link>
                  </div>
                ))}
              </div>
            </Card>
            <Footer />
          </Page>
        </AppProvider>
      )}
    </>
  );
};

export default Setting;
