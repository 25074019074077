import {
  Card,
  Button,
  Divider,
  Grid,
  InlineStack,
  Page,
  Text,
  BlockStack,
  ChoiceList,
  TextField,
  Select,
} from "@shopify/polaris";
import React, { useState , useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_delivery_policy = () => {
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  let [notifyBackInStock, setNotifyBackInStock] = useState([]);
  // let [notifyRestock, setNotifyRestock] = useState(false);
  // let [inventoryPolicy, setInventoryPolicy] = useState([]);
  // let [notificationRate, setNotificationRate] = useState("1");
  // let [numberOfCustomers, setNumberOfCustomers] = useState("10");
  // let [notificationOrder, setNotificationOrder] = useState("old");
  // let [deliveryInterval, setDeliveryInterval] = useState("1");
  // let [minQtySend, setMinQtySend] = useState("1");

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      const deliveryPolicy = JSON.parse(result?.data?.data?.delivery_policy);

      if (deliveryPolicy) {
        setNotifyBackInStock(deliveryPolicy.notifyBackInStock || []);
        // setNotifyRestock(deliveryPolicy.notifyRestock || false);
        // setInventoryPolicy(deliveryPolicy.inventoryPolicy || []);
        // setNotificationRate(deliveryPolicy.notificationRate || "1");
        // setNumberOfCustomers(deliveryPolicy.numberOfCustomers || "10");
        // setNotificationOrder(deliveryPolicy.notificationOrder || "old");
        // setDeliveryInterval(deliveryPolicy.deliveryInterval || "1");
        // setMinQtySend(deliveryPolicy.minQtySend || "1");
      }
      
      setSettingID(result?.data?.data?.id);
    }
  };
  let handleSubmit = async() => {
    setLoading(true);
    let data = {
      notifyBackInStock,
      // inventoryPolicy,
      // notificationRate,
      // numberOfCustomers,
      // notificationOrder,
      // deliveryInterval,
      // minQtySend,
    };
    let data_back_in_stock = {
      delivery_policy:data,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
    console.log(data);
  };

  return (
    <Page title="Delivery policy" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Notify on back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <h1 className="back-in-stock-right-card-title-style">
                  Notify customers when a product is available again in stock.
                </h1>
                <ChoiceList
                  choices={[
                    {
                      label:
                        "I will personally notify customers when the product is restocked.",
                      value: "self",
                    },
                    { label: "Send notification automatically", value: "auto" },
                  ]}
                  selected={notifyBackInStock}
                  onChange={setNotifyBackInStock}
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
          {notifyBackInStock[0] == "auto" && (
            <>
              {/* <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
                <Text as="h1" variant="headingSm">
                  Status
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
                <Card>
                  <InlineStack
                    gap={"400"}
                    align="space-between"
                    blockAlign="center"
                  >
                    <Text as="h1" variant="headingSm">
                      Will notify all subscribers once a product is restocked
                    </Text>
                    <Button
                      onClick={() => setNotifyRestock(!notifyRestock)}
                      variant={notifyRestock ? "secondary" : "primary"}
                      loading={loading}
                    >
                      {notifyRestock ? "Disable" : "Enable"}
                    </Button>
                  </InlineStack>
                </Card>
              </Grid.Cell> */}
              {/* <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
                <Text as="h1" variant="headingSm">
                  With inventory policy
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
                <Card>
                  <BlockStack gap={"300"}>
                    <Text as="h1" variant="headingSm">
                      Display only products that are out of stock and have an
                      inventory policy in place.
                    </Text>
                    <ChoiceList
                      allowMultiple
                      choices={[
                        {
                          label: "Send email notification automatically",
                          value: "email-automatically",
                        },
                        {
                          label:
                            'Enable notifications for products set to "Pre-order" status.',
                          value: "notification-on-pre-order-status",
                        },
                        {
                          label:
                            'When the product is enabled, allows sending notifications "Continue selling when out of stock"',
                          value: "continue-selling-out-of-stock",
                        },
                        {
                          label:
                            "When the product is draft, allow sending notification",
                          value: "draft-product-notification",
                        },
                      ]}
                      selected={inventoryPolicy}
                      onChange={setInventoryPolicy}
                    />
                    {…}
                  </BlockStack>
                </Card>
              </Grid.Cell> */}
            </>
          )}
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_delivery_policy;
