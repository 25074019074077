import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
  Card,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const SubmissionDetail = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };

  const [loading, setLoading] = useState(false);
  const [loadingmail, setLoadingMail] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [emailstatus , setEmailStatus] = useState(0);
  let { id } = useParams();

  let [data, setData] = useState({});
  let [note, setNote] = useState("");
  let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  let [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let dummyData = {
    email: "abc@abc.com",
    productName: "swiss knife",
  };

  useEffect(() => {
    // setData(dummyData);
    handleSubmissionDetails();
  }, []);

  let handleSubmissionDetails = async() => {
    let data = {
      store_client_id: store_client_id,
      selectedResources: id,
    };
    try {  
      let res = await ApiCall("POST", "/get_submission_details", data, header);  
      if (res.data.statusCode === 200) {  
         setData(res.data.data);
         console.log(res.data.data)
         setNote(res?.data?.data?.note);
         setEmailStatus(res?.data?.data?.email_sent);
      } 
  } catch (error) {  
      console.error("API call failed: ", error);  
  } 
  };
  let handleSendEmailClick = () => {
    toggleEmailModal();
  };

  let handleDeleteSubmissionClick = () => {
    toggleDeleteModal();
  };

  let toggleDeleteModal = () => {
    shopify.modal.toggle("delete-modal");
  };
  let toggleEmailModal = () => {
    shopify.modal.toggle("email-modal");
  };
  let handleDeleteSubmit = () => {
    handleRemoveTableRecordSubmit();
  };
  let handleRemoveTableRecordSubmit = async() => {
   
    setLoadingDelete(true);

    let res = await ApiCall("DELETE", "/delete-submission", { id:id  }, header);
    console.log("items deleted", id);
    if (res?.data?.statusCode === 200) {
      navigate("/back-in-stock");
    }
    setLoadingDelete(false);
    
  };
  let handleEmailSubmit = async() => {
    setLoadingMail(true);

    let data = {
      store_client_id: store_client_id,
      selectedResources: id,
    };
    try {  
      let res = await ApiCall("POST", "/send-mail-back-in-stock", data, header);  
      if (res.data.statusCode === 200) {
        setEmailStatus(1);
        console.log("Email sent successfully: ", res.data.message);
      } else {
        console.error("Error sending email: ", res.data.message);
      }
    } catch (error) {
      console.error("API call failed: ", error);
    }
    console.log("handleEmailSubmit");
    setLoadingMail(false);
    
  };

  let handleSubmit = async() => {
    setLoading(true);
  
    const data = {
      id: id,
      note: note,
    };
    let response = await ApiCall("PUT", "/update_submission_details", data, header);
    if (response.data.statusCode === 200 && response.data.status === "success") {
      
    }
    setLoading(false);

  };
  
  return (
    <Page
      title={data.email}
      subtitle={data?.created_at && !isNaN(Date.parse(data.created_at)) ? format(new Date(data.created_at), "yyyy-MM-dd HH:mm:ss") : "N/A"}
      backAction={{ onAction: handleBackAction }}
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Send email",
              onAction: handleSendEmailClick,
            },
            {
              content: "Delete submission",
              destructive: true,
              onAction: handleDeleteSubmissionClick,
            },
          ],
        },
      ]}
    >
      <BlockStack gap={"300"}>
        <Card>
          <Box borderColor="border" borderBlockEndWidth="1" paddingBlockEnd={"400"}>
            <InlineStack gap={"400"}>
              <BlockStack gap={"400"}>
                <Text as="h3" variant="headingSm">
                  User email
                </Text>
                <Text as="h3" variant="headingSm">
                  Product
                </Text>
                <Text as="h3" variant="headingSm">
                  SKU
                </Text>
                <Text as="h3" variant="headingSm">
                  Status
                </Text>
                <Text as="h3" variant="headingSm">
                  Locale
                </Text>
                <Text as="h3" variant="headingSm">
                  Subscribed date
                </Text>
                <Text as="h3" variant="headingSm">
                  Email last send
                </Text>
                <Text as="h3" variant="headingSm">
                  Email unsubscribed
                </Text>
              </BlockStack>
              <BlockStack gap={"400"}>
                <Text as="p" variant="bodyMd">
                  {data?.customer_email}
                </Text>
                <Text as="p" variant="bodyMd">
                  {data?.product_title}
                </Text>
                <Text as="p" variant="bodyMd">
                {data?.sku}
                </Text>
                <Text as="p" variant="bodyMd">
                
                <Badge tone={emailstatus == 1 ? "success": ""}>{emailstatus == 1 ? "Email sent" : "Email Pending"}</Badge>
                </Text>
                <Text as="p" variant="bodyMd">
                {data?.locale}
                </Text>
                <Text as="p" variant="bodyMd">
                {data?.created_at && !isNaN(Date.parse(data.created_at)) ? format(new Date(data.created_at), "yyyy-MM-dd HH:mm:ss") : "N/A"}
                </Text>
                <Text as="p" variant="bodyMd">
                {data?.updated_at && !isNaN(Date.parse(data.updated_at)) ? format(new Date(data.updated_at), "yyyy-MM-dd HH:mm:ss") : "N/A"}
                </Text>
                <Text as="p" variant="bodyMd">
                {data?.unsubscribe == 0 ? "No" : "Yes"}
                </Text>
              </BlockStack>
            </InlineStack>
          </Box>
          <TextField
              type="text"
              label="Note"
              value={note}
              onChange={setNote}
              multiline={5}
            />
        </Card>
        <InlineStack align="end">
          <Button variant="primary" loading={loading} onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>

      {/* delete confirmation modal */}
      <Modal id="delete-modal">
        <TitleBar title="Delete submission?">
          <button variant="primary" loading={loadingdelete} onClick={handleDeleteSubmit}>
            Delete
          </button>
          <button onClick={toggleDeleteModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          All submissions data also are removed. Once deleted, it cannot be
          completed. Are you sure you want to delete?
        </Box>
      </Modal>

      {/* email confirmation modal */}
      <Modal id="email-modal">
        <TitleBar title="Are you sure you want to send email?">
          <button variant="primary" loading={loadingmail} onClick={handleEmailSubmit}>
            Send
          </button>
          <button onClick={toggleEmailModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          You're about to send out an email, are you sure you want to send it
          out?
        </Box>
      </Modal>
    </Page>
  );
};

export default SubmissionDetail;
