import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  Box,
  ChoiceList,
  DropZone,
  BlockStack,
  Link,
  Page,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import { ExportIcon, ImportIcon, NoteIcon } from "@shopify/polaris-icons";
import MainTable from "./MainTable";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

import { ApiCall, GetApiCall } from "../../../helpers/axios";

import { useSelector } from "react-redux";
const SubmissionPage = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  let [isBannerVisible, setIsBannerVisible] = useState(true);
  let [selectedModalExportOption, setSelectedModalExportOption] = useState("");
  const [files, setFiles] = useState([]);
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [loading, setLoading] = useState(false);
  let handleAddSubscriptionBtnClick = () => {
    navigate("/back-in-stock/add-subscriber");
  };
  let showModal = (modalId) => {
    shopify.modal.show(modalId);
  };
  let hideModal = (modalId) => {
    shopify.modal.hide(modalId);
  };
  let handleModalExportBtnClick = async () => {
    setLoading(true);
    let data = {
      store_client_id: store_client_id,
      export_type: selectedModalExportOption[0],
      export_data_all: true,
    };
    console.log(selectedModalExportOption[0]);
    try {  
      let res = await ApiCall("POST", "/export_submission", data, header);  
      if (res.data.statusCode === 200) {  
          const headers = [
              'customer_email', 'customer_phone', 'product_id', 'product_title', 
              'product_image', 'product_tags', 'variant_id', 'variant_title', 
              'sku', 'inventory_quantity', 'inventory_id'
          ];
          
          // Generate CSV content
          const generateCSV = (data) => {
              const rows = data.map(row => 
                  headers.map(header => `"${row[header] || ''}"`).join(',')
              );
              return [headers.join(','), ...rows].join('\n');
          };
          // Prepare CSV data from the response
          const csvData = generateCSV(res?.data?.data?.select_data);  
  
          // Create a Blob from the CSV data  
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });  
  
          // Create a link element  
          const link = document.createElement('a');  
  
          // Create a URL for the Blob and set it as the link's href  
          const url = URL.createObjectURL(blob);  
          link.setAttribute('href', url);  
  
          // Set the download attribute with a filename  
          link.setAttribute('download', 'SubmissionsExports.csv');  
  
          // Append the link to the document (it needs to be part of the DOM)  
          document.body.appendChild(link);  
  
          // Programmatically click the link to trigger the download  
          link.click();  
  
          // Clean up the URL and remove the link from the DOM  
          URL.revokeObjectURL(url);  
          document.body.removeChild(link);  
      } else {  
          console.error("Error downloading CSV: ", res.data.message);  
      }  
  } catch (error) {  
      console.error("API call failed: ", error);  
  }  
    
    setLoading(false);
    hideModal("export-submission-modal");
  };
  let handleModalImportBtnClick = () => {
    
    //read csv file in files
    const reader = new FileReader();
    reader.onload = async (event) => {
      const text = event.target.result;
      const lines = text.split('\n'); // Split text into lines
      const dataArray = lines
      .slice(1) // Skip the first line (header)
      .filter(line => line.trim() !== '') // Filter out empty lines
      .map(line => {
        // Use a regular expression to split the line while preserving quoted fields
        const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/;
        return line.split(regex).map(value => 
          value.trim().replace(/^"(.*)"$/, '$1') // Remove surrounding double quotes
        );
      });
      console.log(dataArray);
      let data = {
        store_client_id: store_client_id,
        import_data: dataArray,
      };
      let res = await ApiCall("POST", "/import_submission", data, header); 
      if (res.data.statusCode === 200) {
        console.log("Imported successfully");
      } else {
        console.log("Imported failed");
      } 
      console.log(text);
    }
    files.forEach(file => reader.readAsText(file));
     
    setLoading(true);
    
    hideModal("import-submissions-modal");
  };
  //   drop zone code
  const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFiles(acceptedFiles);
  };
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <Box padding={"4"}>
      <BlockStack>
        {files.map((file, index) => (
          <BlockStack align="center" key={index}>
            <Thumbnail size="small" alt={file.name} source={NoteIcon} />
            <div>
              {file.name}{" "}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </BlockStack>
        ))}
      </BlockStack>
    </Box>
  );
  // banner
  let closeBanner = () => {
    setIsBannerVisible(false);
  };
  let handleGotoSettingClick = () => {
    console.log("handleGotoSettingClick");
  };
  let handleContactUsClick = () => {
    console.log("handleContactUsClick");
  };

  return (
    <Page
      title="Submission"
      primaryAction={{
        content: "Add subscription",
        onAction: handleAddSubscriptionBtnClick,
      }}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportIcon,
          onAction: () => showModal("export-submission-modal"),
        },
        {
          content: "Import",
          icon: ImportIcon,
          onAction: () => showModal("import-submissions-modal"),
        },
      ]}
    >
      <BlockStack gap={"300"}>
        {/* {isBannerVisible && (
          <Banner
            tone="warning"
            title="Discontinuation of SMS product restock notifications"
            onDismiss={closeBanner}
            action={{
              content: "Go to Setting > Notification Channels",
              onAction: handleGotoSettingClick,
            }}
            secondaryAction={{
              content: "Contact us",
              onAction: handleContactUsClick,
            }}
          >
            <Text as="p" variant="bodyMd">
              We regret to inform you that the SMS notification feature for
              product restocks in our application has been discontinued due to
              restrictions on sending permissions in certain countries. Please
              disable the SMS channel in the channel settings.
            </Text>
            <Text as="p" variant="bodyMd">
              We apologize for any inconvenience this may cause. If you have any
              questions or concerns, please feel free to reach out to our
              support team. Thank you for your understanding and patience.
            </Text>
          </Banner>
        )} */}
        <MainTable />
      </BlockStack>
      {/* export modal */}
      <Modal id="export-submission-modal">
        <TitleBar title="Export submission">
          <button variant="primary" onClick={handleModalExportBtnClick}>
            Export
          </button>
          <button onClick={() => hideModal("export-submission-modal")}>
            Close
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <ChoiceList
            title={
              <Text as="h1" variant="headingMd">
                Export
              </Text>
            }
            choices={[
              {
                label: "All submissions",
                value: "all",
              },
              {
                label: "Email sent",
                value: "email-sent",
              },
              {
                label: "Email pending",
                value: "email-pending",
              },
            ]}
            selected={selectedModalExportOption}
            onChange={setSelectedModalExportOption}
          />
        </Box>
      </Modal>
      {/* import modal */}
      <Modal id="import-submissions-modal">
        <TitleBar title="Import submissions by CSV">
          <button variant="primary" onClick={handleModalImportBtnClick}>
            Import
          </button>
          <button onClick={() => hideModal("import-submissions-modal")}>
            Close
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <BlockStack gap={"1"}>
            <DropZone
              label={
                <Text as="p" variant="bodyMd">
                  Download <Link>a sample CSV template</Link> to see an example
                  of the format required
                </Text>
              }
              accept=".csv"
              onDrop={handleDropZoneDrop}
            >
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </BlockStack>
        </Box>
      </Modal>
    </Page>
  );
};

export default SubmissionPage;
