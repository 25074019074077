import { useState, useCallback, useEffect } from "react";
import {
  Page,
  ProgressBar,
  Select,
  Button,
  FormLayout,
  SkeletonBodyText,
  Text,
  SkeletonDisplayText,
  TextField,
  Icon,
  Toast,
  DataTable,
  Spinner,
  BlockStack,
  Card,
} from "@shopify/polaris";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import { ApiCall, GetApiCall, ShopifyCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { setShopData } from "../redux/commonSlice";
import { useDispatch } from "react-redux";
import Footer from "./Footer";
import noimg from "../assets/images/noimg.jpg";
import app_block_enable from "../assets/images/app_block_enable.png";
const OnBoarding = () => {
  const dispatch = useDispatch();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const shop_name = shop_data.store_name;
  const onb_info_status = shop_data.onb_info_status;
  const app_onb_bnr_status = shop_data.app_onb_bnr_status;
  const onb_status = shop_data.onboarding_status;
  const store_name = shop_data.store_name;
  const store_client_id = shop_data.store_client_id;

  const header = { authentication: shopinfo.auth_token };
  const [onbStatus, setOnbstatus] = useState(0);
  const [themes, setthemes] = useState([]);
  const [selected, setSelected] = useState(shopinfo.store_theme_id);
  const [selectedtab, setSelectedTab] = useState(0);
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPro, setLoadingPro] = useState(false);
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [email, setEmail] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [bannerStatusInfo, setBannerStatusInfo] = useState("0");
  const [bannerStatusApp, setBannerStatusApp] = useState("0");
  const [firstTab, setfirstTab] = useState("0");
  const [secondTab, setsecondTab] = useState("0");
  const [thirdTab, setthirdTab] = useState("0");
  const [prevStatus, setPrevStatus] = useState(true);

  useEffect(() => {
    getStoreData();
    setOnbstatus(onb_status);
    if (onb_status === "2") {
      setProgress(66);
      setfirstTab(1);
      setsecondTab(1);
      setSelectedTab(0);
    }
    if (onb_status === "1") {
      setfirstTab(1);
    }

    GetProductList("", selected);
    setBannerStatusInfo(onb_info_status);
    setBannerStatusApp(app_onb_bnr_status);
  }, [shop_name, selected]);

  const handleSelectChange = useCallback((value) => {
    setSelected(Number(value));
  }, []);

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const getStoreData = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/store_data?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        //  setBannerStatus(res?.data?.data[0].all_pro_bnr_status);
      }
    }
  };

  const closeBannerInfo = async () => {
    let response = await ApiCall("PUT", "/onb_info_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatusInfo(response.data?.data?.onb_info_status);
    }
  };

  const closeBannerApp = async () => {
    let response = await ApiCall("PUT", "/app_onb_bnr_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatusApp(response.data?.data?.app_onb_bnr_status);
    }
  };
  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={5000}
    />
  ) : null;

  const redirectToSettings = async () => {
    if (Object.keys(shop_data).length > 0) {
      setLoading(true);
      let data = {
        store_client_id: shop_data.store_client_id,
        shop_data: shop_data,
        onb_status: 3,
      };
      let res = await ApiCall("PUT", "/app-enable", data, header);
      if (res?.data?.statusCode === 200) {
        setProgress(100);
        let shop_Data = Object.assign({}, shopinfo.shopInfo);
        shop_Data = Object.assign(shop_Data, {
          app_status: 1,
          onboarding_status: 1,
        });
        let shop_data = {
          shopInfo: shop_Data,
          auth_token: shopinfo.auth_token,
        };
        dispatch(setShopData(shop_data));
        setLoading(false);
      }
      window.location.reload(true);
      navigate("/");
    }
  };

  let getThemes = async (store_client_id) => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "POST",
        `/themes?store_client_id=${store_client_id}&status=onb`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setReady(true);
        setthemes(res?.data?.data?.themes);
        let all_themes = res?.data?.data?.themes;
        const publishedTheme = all_themes.find(
          (theme) => theme && theme.role === "main"
        );
        setSelected(parseInt(publishedTheme.value));
      }
    }
  };

  useEffect(() => {
    getThemes(shop_data.store_client_id);
    setEmail(shop_data.email);
  }, [shop_data]);

  const AppStatus = async () => {
    let result = await GetApiCall(
      "GET",
      `/app_block_status_change?store_client_id=${store_client_id}`,
      header
    );
    let block_status = result.data ? result.data.data : false;
    console.log(result);
    return block_status;
  };

  const handleStatusCheckAndAction = async (status, backstatus) => {
    setLoadingPro(true);
    const checkStatus = async (retries = 5, delay = 5000) => {
      for (let i = 0; i < retries; i++) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        const block_status = await AppStatus();
        if (block_status !== prevStatus) {
          setLoadingPro(false);
          setPrevStatus(false);
          nextStep(status, backstatus);
          return;
        }
      }
    };

    checkStatus();
  };

  const nextStep = async (status, backstatus) => {
    GetProductList("", selected);
    let data = {
      status,
      store_theme_id: selected,
    };
    let res = await ApiCall("PUT", "/onb_status", data, header);
    if (res?.data?.statusCode === 200 && status != 2) {
      setOnbstatus(status);
      setToastMessage("Theme selected successfully");
      toggleActiveToast();
      setProgress(33);
    }
  };

  const GetProductList = async (value, theme_id) => {
    setLoadingPro(true);
    let res = "";
    if (Object.keys(shop_data).length > 0) {
      let data = {
        store_client_id: shop_data.store_client_id,
        shop_info: shop_data,
        shop: store_name,
        page_query: "after",
        limit: 5,
        type: "onb",
      };
      if (value && value.length >= 3) {
        res = await ShopifyCall(
          "POST",
          `/search-product?search_key=${value}`,
          data,
          header
        );
      } else {
        res = await ShopifyCall("POST", `/product-api-list`, data, header);
      }
      setLoadingPro(false);

      const rows = [];
      if (res?.data?.status === "success" && res?.data?.statusCode === 200) {
        let res_type = res?.data?.data?.type ? res?.data?.data?.type : "";
        let produts = res?.data?.data?.products;

        if (produts.length > 0 && res_type == "") {
          produts.map(({ featuredImage, title, id }) => {
            rows.push([
              <div className="d-flex align-items-center">
                <img
                  width={"40px"}
                  height={"40px"}
                  src={`${featuredImage ? featuredImage.originalSrc : noimg}`}
                  alt=""
                />
                <div className="px-3">{title}</div>
              </div>,
              <>
                <div className="edit_btn action_edit">
                  <Button
                    onClick={() => addProduct(id.split("/").reverse()[0])}
                  >
                    <Icon source={PlusCircleIcon} color="base" />
                  </Button>
                </div>
              </>,
            ]);
          });
        } else if (res_type === "DB_product") {
          rows.push([
            <div className="d-flex align-items-center">
              <img
                width={"40px"}
                height={"40px"}
                src={produts?.images?.[0]?.src || noimg}
                alt=""
              />
              <div className="px-3">
                {produts.title} <b>( selected )</b>
              </div>
            </div>,
            <div className="font-weight-600 action_edit">
              <Button
                variant="primary"
                onClick={() => {
                  setfirstTab(1);
                  setsecondTab(1);
                  nextStep(1, 0);
                }}
              >
                Next step
              </Button>
            </div>,
          ]);
          const preview_theme = theme_id ? `?preview_theme_id=${theme_id}` : "";
          setLink(
            `https://${shop_data.store_name}/products/${produts.handle}${preview_theme}`
          );
        } else {
          rows.push([<div className="no-data-found">No data found.</div>]);
        }
      } else {
        rows.push([<div className="no-data-found">No data found.</div>]);
      }
      setProductData(rows);
    }
  };

  const addProduct = async (id) => {
    let data = { product_id: id, shop_info: shop_data };
    let res = await ApiCall("PUT", "/onbProduct", data, header);
    if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
      let data = {
        shop_info: shop_data,
        is_all_product: 1,
      };
      // await ApiCall("PUT", "/productVariant_bulk_update", data, header);
      setLink(res?.data?.data);
      nextStep(2, 1);
      setSelectedTab(0);
      setsecondTab(1);
      setProgress(66);
    }
  };

  return (
    <div>
      <Page>
        <Card>
          <BlockStack gap={"200"}>
            <Text as="h1" variant="headingSm">
              Setup guide
            </Text>
            <div className="onb_body">
              <div>
                <div>
                  Welcome to Pre-order. Let’s start with select and install
                  theme
                </div>
                <div className="d-flex">
                  <div className="mt-2 step_width">1 of 3 step complete</div>
                  <div className="progress_bar mt-3">
                    <ProgressBar progress={progress} size="small" />
                  </div>
                </div>
              </div>
              <div>
                <Tabs
                  selectedIndex={selectedtab}
                  onSelect={(index) => setSelectedTab(index)}
                >
                  <TabList>
                    {firstTab == 1 ? (
                      <li
                        class="react-tabs__tab react-tabs__tab--selected"
                        role="tab"
                        aria-selected="true"
                        data-rttab="true"
                        aria-disabled="true"
                      >
                        <svg
                          class="onb_svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                            fill="#6D7175"
                          ></path>
                        </svg>
                        Select a theme
                      </li>
                    ) : (
                      <>
                        <Tab disabled>
                          <svg
                            className="onb_svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                              fill="#6D7175"
                            />
                          </svg>
                          Select a theme
                        </Tab>
                      </>
                    )}
                    {secondTab == 1 ? (
                      <li
                        class="react-tabs__tab react-tabs__tab--selected"
                        role="tab"
                        aria-selected="true"
                        aria-disabled="true"
                      >
                        <svg
                          class="onb_svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                            fill="#6D7175"
                          ></path>
                        </svg>
                        Select a product
                      </li>
                    ) : (
                      <>
                        <Tab disabled>
                          <svg
                            className="onb_svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                              fill="#6D7175"
                            />
                          </svg>
                          Select a product
                        </Tab>
                      </>
                    )}
                    {thirdTab == 1 ? (
                      <li
                        class="react-tabs__tab react-tabs__tab--selected"
                        role="tab"
                        aria-selected="true"
                      >
                        <svg
                          class="onb_svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                            fill="#6D7175"
                          ></path>
                        </svg>
                        Check
                      </li>
                    ) : (
                      <>
                        <Tab disabled>
                          <svg
                            className="onb_svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                              fill="#6D7175"
                            />
                          </svg>
                          Check
                        </Tab>
                      </>
                    )}
                  </TabList>
                  {firstTab == 0 ? (
                    <TabPanel>
                      <div className="panel-content">
                        {loadingPro ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <Spinner
                              accessibilityLabel="Spinner example"
                              size="large"
                            />
                          </div>
                        ) : (
                          <FormLayout>
                            {ready ? (
                              <>
                                <Select
                                  label="Select a theme for applying the app"
                                  options={themes}
                                  onChange={handleSelectChange}
                                  value={selected}
                                />
                                {onbStatus == 0 ? (
                                  <>
                                    <div key={`section1`} className="mt-3">
                                      {bannerStatusApp === "0" && (
                                        <>
                                          <div className="banner">
                                            <div className="banner-head">
                                              <div className="banner-heading">
                                                <span className="banner-Icon">
                                                  <svg className="banner-Icon__Svg">
                                                    <path
                                                      d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"
                                                      fill="rgb(0 33 51)"
                                                    ></path>
                                                    <path
                                                      d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                                      fill="rgb(0 33 51)"
                                                    ></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                                                      fill="rgb(0 33 51)"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                <h2>
                                                  App Embeds in Theme Setting
                                                </h2>
                                              </div>
                                              <button
                                                className="banner-close-btn"
                                                onClick={() => {
                                                  closeBannerApp();
                                                }}
                                              >
                                                <span className="banner-Icon">
                                                  <svg
                                                    viewBox="0 0 20 20"
                                                    className="banner-Icon__Svg"
                                                    fill="rgb(0 33 51)"
                                                  >
                                                    <path
                                                      fill="rgb(0 33 51)"
                                                      d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </button>
                                            </div>
                                            <div className="banner-content">
                                              <span>
                                                <p>
                                                  <div>
                                                    Enable Pre-order app embed
                                                    in theme setting to install
                                                    the app to your your theme
                                                  </div>
                                                </p>
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="mt-3 redirect_to_theme">
                                        Go to theme setting {">"} App Embeds{" "}
                                        {">"} Click On Save Button {""}
                                        <a
                                          className="Polaris-Button"
                                          onClick={() =>
                                            handleStatusCheckAndAction(1, 0)
                                          }
                                          loading={loading}
                                          style={{
                                            marginLeft: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "rgb(0 122 92)",
                                            color: "#FFFFFF",
                                          }}
                                          variant="primary"
                                          target="_blank"
                                          href={
                                            selected !== undefined
                                              ? `https://${shop_name}/admin/themes/${selected}/editor?context=apps&template=${selected}&activateAppId=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block`
                                              : `https://${shop_name}/themes/`
                                          }
                                        >
                                          Activate Now
                                        </a>
                                        <div className="pre_order_app_block">
                                          <img
                                            src={app_block_enable}
                                            alt="Enable app embed"
                                          />
                                        </div>
                                        <br></br>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div key={`section2`} className="mt-3">
                                      {toastMarkup}
                                      <div className="mt-3 next_step">
                                        <Button
                                          variant="primary"
                                          onClick={() => {
                                            setfirstTab(1);
                                            nextStep(1, 0);
                                          }}
                                        >
                                          Next step
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <Card>
                                <Text>
                                  <SkeletonDisplayText size="small" />
                                  <SkeletonBodyText lines={2} />
                                </Text>
                                <SkeletonBodyText lines={1} />
                              </Card>
                            )}
                          </FormLayout>
                        )}
                      </div>
                    </TabPanel>
                  ) : null}
                  {secondTab == 0 ? (
                    <TabPanel>
                      <div className="panel-content">
                        <TextField
                          prefix={<Icon source={SearchIcon} color="base" />}
                          value={searchValue}
                          onChange={(value) => {
                            setSearchValue(value);
                            GetProductList(value);
                          }}
                          clearButton
                          onClearButtonClick={() => setSearchValue("")}
                          autoComplete="off"
                          placeholder="Search product"
                        />
                        <div
                          className={productData.length === 1 ? "db_pro" : ""}
                        >
                          {loadingPro ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Spinner
                                accessibilityLabel="Spinner example"
                                size="large"
                              />
                            </div>
                          ) : (
                            <>
                              {productData.length > 0 && (
                                <DataTable
                                  columnContentTypes={["text", "text"]}
                                  headings={[
                                    <div className="preorder_pro_heading">
                                      Product
                                    </div>,
                                    <div className="onbpro_heading">
                                      Action
                                    </div>,
                                  ]}
                                  rows={productData}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  ) : null}
                  {thirdTab == 0 ? (
                    <TabPanel>
                      <div className="panel-content">
                        <FormLayout>
                          {/* {link && (
                        <>
                          <a href={link} target="_blank">
                            Verify that the Pre-order appears on the product
                            page
                            <svg
                              className="ml-1"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M6.80039 1.1999C6.80039 0.987729 6.88468 0.784246 7.03471 0.634217C7.18473 0.484188 7.38822 0.399902 7.60039 0.399902H10.8004C11.0126 0.399902 11.216 0.484188 11.3661 0.634217C11.5161 0.784246 11.6004 0.987729 11.6004 1.1999V4.3999C11.6004 4.61208 11.5161 4.81556 11.3661 4.96559C11.216 5.11562 11.0126 5.1999 10.8004 5.1999C10.5882 5.1999 10.3847 5.11562 10.2347 4.96559C10.0847 4.81556 10.0004 4.61208 10.0004 4.3999V3.1311L5.76599 7.3655C5.61511 7.51123 5.41303 7.59186 5.20327 7.59004C4.99351 7.58822 4.79286 7.50408 4.64454 7.35576C4.49621 7.20743 4.41207 7.00678 4.41025 6.79702C4.40843 6.58727 4.48906 6.38518 4.63479 6.2343L8.86919 1.9999H7.60039C7.38822 1.9999 7.18473 1.91562 7.03471 1.76559C6.88468 1.61556 6.80039 1.41208 6.80039 1.1999ZM0.400391 3.1999C0.400391 2.88164 0.526819 2.57642 0.751863 2.35137C0.976906 2.12633 1.28213 1.9999 1.60039 1.9999H4.40039C4.61256 1.9999 4.81605 2.08419 4.96608 2.23422C5.11611 2.38425 5.20039 2.58773 5.20039 2.7999C5.20039 3.01208 5.11611 3.21556 4.96608 3.36559C4.81605 3.51562 4.61256 3.5999 4.40039 3.5999H2.00039V9.9999H8.40039V7.5999C8.40039 7.38773 8.48468 7.18425 8.63471 7.03422C8.78473 6.88419 8.98822 6.7999 9.20039 6.7999C9.41256 6.7999 9.61605 6.88419 9.76608 7.03422C9.91611 7.18425 10.0004 7.38773 10.0004 7.5999V10.3999C10.0004 10.7182 9.87396 11.0234 9.64892 11.2484C9.42388 11.4735 9.11865 11.5999 8.80039 11.5999H1.60039C1.28213 11.5999 0.976906 11.4735 0.751863 11.2484C0.526819 11.0234 0.400391 10.7182 0.400391 10.3999V3.1999Z"
                                fill="#2C6ECB"
                              />
                            </svg>
                          </a>
                        </>
                      )} */}
                          <div>
                            {bannerStatusInfo === "0" && (
                              <>
                                {/* <Banner status="info" onDismiss={() => { closeBannerInfo() }} >
                          <div >
                            You can skip step 1 , step 2 now and again do in settings.If you can’t see the pre-order, Please contact us{" "}
                            <Link target="_blank" url={`mailto:support@conversionbat.com`}>here</Link>
                          </div>
                        </Banner> */}
                                <div class="banner">
                                  <div class="banner-head">
                                    <div class="banner-heading">
                                      <span class="banner-Icon">
                                        <svg class="banner-Icon__Svg">
                                          <path
                                            d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"
                                            fill="rgb(0 33 51)"
                                          ></path>
                                          <path
                                            d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                            fill="rgb(0 33 51)"
                                          ></path>
                                          <path
                                            fill-rule="evenodd"
                                            d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                                            fill="rgb(0 33 51)"
                                          ></path>
                                        </svg>
                                      </span>
                                      <h2>App Settings</h2>
                                    </div>
                                    <button
                                      class="banner-close-btn"
                                      onClick={() => {
                                        closeBannerInfo();
                                      }}
                                    >
                                      <span class="banner-Icon">
                                        <svg
                                          viewBox="0 0 20 20"
                                          class="banner-Icon__Svg"
                                          fill="rgb(0 33 51)"
                                        >
                                          <path
                                            fill="rgb(0 33 51)"
                                            d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                  <div class="banner-content">
                                    <span>
                                      <p>
                                        The Pre Order Button/Badge may not
                                        appear for some themes and would require
                                        customization
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="mt-3">
                              <Button
                                variant="primary"
                                onClick={() => redirectToSettings()}
                                loading={loading}
                              >
                                Go to Dashboard
                              </Button>
                            </div>
                          </div>
                        </FormLayout>
                      </div>
                    </TabPanel>
                  ) : null}
                </Tabs>
              </div>
            </div>
          </BlockStack>
        </Card>
        <Footer />
      </Page>
    </div>
  );
};

export default OnBoarding;
